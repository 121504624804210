import { Button, Grid, Typography } from '@mui/material';
import React from 'react';

const CountDownCard = ({ title, bottom, time, action, disabled }) => {
    const [thisState, setThisState] = React.useState({
        countDown: {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            ready: new Date() > new Date(time),
        }
    });

    const calculateTimeLeft = () => {
        let difference = +new Date(time) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
                ready: false,

            };
        } else {
            timeLeft = {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
                ready: true,

            };
        }

        setThisState({ ...thisState, countDown: timeLeft });
    }

    React.useEffect(() => {
        calculateTimeLeft();
        const timer = setInterval(() => {
            calculateTimeLeft();
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    if (disabled) {
        return null;
    }

    return (
        <Grid container>
            <Grid
                item
                xs={12}
                sx={{
                    marginTop: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "16px",
                    paddingTop: "30px",

                    // background: "linear-gradient(90deg, #21cbf4 0%, #8b18fa 100%)",
                    background: "linear-gradient(90deg, rgb(8 8 8) 0%, rgb(60 0 118) 100%)",
                    color: "#FFFFFF",
                    borderRadius: "10px",
                    boxShadow: "0px 0px 1px rgb(0 0 0 / 50%);",
                }}
            >
                {typeof title === "string" && (
                    <Typography variant="h3" sx={{ fontWeight: "bold", marginBottom: 2 }}>
                        {title}
                    </Typography>
                )}
                {typeof title !== "string" && title}
                <Button
                    style={{
                        margin: 10, display: "flex", justifyContent: "center", padding: "10px 20px",
                        borderRadius: "50px",
                        background: "linear-gradient(90deg, #ff8a00 0%, #e52e71 100%)",
                        color: "#FFFFFF",
                        // hover effect
                        "&:hover": {
                            background:
                                "linear-gradient(90deg, #e52e71 0%, #ff8a00 100%)",
                        },
                        cursor: thisState.countDown.ready ? "pointer" : "default",

                    }}
                    onClick={thisState.countDown.ready ? () => {
                        if (action.onClick) {
                            action.onClick();
                        }
                    } : null}

                >
                    {!thisState.countDown.ready && (
                        <Typography variant="h1" sx={{ fontWeight: "bold", marginBottom: 2, fontSize: "3rem !important" }}>

                            {thisState.countDown.days} d�as {thisState.countDown.hours} h {" "} {thisState.countDown.minutes} m {thisState.countDown.seconds} s
                        </Typography>
                    )}
                    {thisState.countDown.ready && (
                        <Typography variant="h1" sx={{ fontWeight: "bold", marginBottom: 2, fontSize: "3rem !important" }}>
                            {action.label}
                        </Typography>
                    )}
                </Button>
                {bottom}
            </Grid>
        </Grid>

    );
}

export default CountDownCard;