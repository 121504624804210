import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import jssPreset from "@mui/styles/jssPreset";
import StylesProvider from "@mui/styles/StylesProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { useDispatch, useSelector } from 'src/store';
import { GoogleOAuthProvider } from "@react-oauth/google";
import { createBrowserHistory } from "history";
import { create } from "jss";
import rtl from "jss-rtl";
import "moment/locale/es";
import "moment/locale/fr";
import { SnackbarProvider } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Router } from "react-router-dom";
import CookiesNotification from "src/components/CookiesNotification";
import GlobalStyles from "src/components/GlobalStyles";
import ScrollReset from "src/components/ScrollReset";
import routes, { renderRoutes } from "src/routes";
import { buildTheme } from "src/theme";
import useSWR from "swr";
import LoadingScreen from "./components/LoadingScreen";
import coreConfig from "./coreConfig";
import {
  getCustomers,
  loadCustomers,
  setCustomerById,
} from "./slices/customers";
import { loadSettings, loadSiteConfig, saveSettings } from "./slices/settings";
import { useDispatch, useSelector } from "./store";
import { apiGet } from "./utils/axios";

const localeMap = {
  en: "en",
  es: "es",
  fr: "fr",
};

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App = () => {
  const dispatch = useDispatch();
  const ws = useRef(null);
  const [tries, setTries] = useState(1);
  const retrySocket = useRef({});
  const {
    adminMode,
    siteConfig,
    siteConfigLoaded,
    direction,
    responsiveFontSizes,
    theme,
    settingsLoaded,
    csrf,
  } = useSelector((state) => state.settings);
  const [currentTheme, setCurrentTheme] = useState(
    buildTheme(
      {
        direction: direction,
        responsiveFontSizes: responsiveFontSizes,
        theme: "LIGHT",
      },
      {}
    )
  );
  const { currentCustomer, customers, customersLoaded } = useSelector(
    (state) => state.customers
  );
  const taxes = useSelector((state) => state.taxes);
  const [connected, setConnected] = useState(false);

  const { user, notifyMessage, loaded } = useSelector((state) => state.users);

  // const { data, error, isValidating } = useSWR(
  //   arkadu.urls.v2.customer.list,
  //   apiGet
  // );

  // useEffect(() => {
  //   // console.log(data, error, isValidating);
  //   if (data) {
  //     dispatch(setCustomers(data));
  //   }
  // }, [data, error]);
  // useEffect(() => {
  //   if (customers.length === 0 && !customersLoaded) {
  //     dispatch(getCustomers());
  //   }
  // }, [customers, customersLoaded]);

  useEffect(() => {
    if (!settingsLoaded) {
      dispatch(loadSettings());
    }
  }, [settingsLoaded]);

  useEffect(() => {
    if (!siteConfigLoaded) {
      dispatch(loadSiteConfig());
      dispatch(loadCustomers());
    }
  }, [siteConfig, siteConfigLoaded]);

  // useEffect(() => {
  //   if (adminMode) {
  //     dispatch(
  //       saveSettings({
  //         settings: { siteConfig: { ...siteConfig, version: 0 } },
  //       })
  //     );
  //   }
  // }, [adminMode]);

  // useEffect(() => {
  //   if (user && !loaded) {
  //     dispatch(loadUserFromStorage());
  //   }
  // }, [user, loaded]);

  useEffect(() => {
    setCurrentTheme(
      buildTheme(
        {
          direction: direction,
          responsiveFontSizes: responsiveFontSizes,
          theme: theme,
        },
        {}
      )
    );
  }, [settingsLoaded, theme]);

  const settingsTheme = buildTheme({
    direction: direction,
    responsiveFontSizes: responsiveFontSizes,
    theme: theme,
  });

  // if (!settingsLoaded || !user) {
  //   return <LoadingScreen message={'loading'} />;
  // }
  if (!siteConfigLoaded) {
    return (
      <ThemeProvider theme={currentTheme}>
        <LoadingScreen message={"Cargando configuraci�n"} />
      </ThemeProvider>
    );
  }
  console.log("siteConfig", siteConfig);
  console.log("coreConfig", coreConfig);
  return (
    <>
      <Helmet>
        <title>{coreConfig.siteName || siteConfig.name}</title>
        <meta
          name="description"
          content={coreConfig.siteDescription || siteConfig.slogan}
        />
        <meta
          name="keywords"
          content={
            coreConfig.siteKeywords ||
            siteConfig.keywords ||
            `Gesti�n, Recaudaci�n, Impuestos, Servicios, Facturaci�n, 
            Automatizaci�n, Control y seguimiento, Citas y control de turnos, 
            IoT, Alcald�as, Municipios, Catastro, Ambiente, Control urbano, 
            Control de obras, Actividades Econ�micas, Econom�a popular, 
            Veh�culos, Publicidad, Licencias, Impuestos, 
            Recaudaci�n, Control de obras, Control urbano, Catastro, 
            Ambiente, Econom�a popular, Veh�culos, Publicidad, Licencias, 
            Impuestos, Recaudaci�n, Control de obras, Control urbano, Catastro, 
            Terminales, Aseo urbano, Polic�a municipal, Control de actividades,
            Fiscalizaci�n.
            `
          }
        />

        <meta
          property="og:title"
          content={coreConfig.siteName || siteConfig.name}
        />
        <meta
          property="og:description"
          content={coreConfig.siteDescription || siteConfig.slogan}
        />
        <meta
          property="og:image"
          content={coreConfig.siteLogo || siteConfig.logo}
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          name="twitter:title"
          content={coreConfig.siteName || siteConfig.name}
        />
        <meta
          name="twitter:description"
          content={coreConfig.siteDescription || siteConfig.slogan}
        />
        <meta
          name="twitter:image"
          content={coreConfig.siteLogo || siteConfig.logo}
        />
        <meta name="twitter:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={currentTheme}>
          <StylesProvider jss={jss}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <GoogleOAuthProvider
                clientId={
                  "781913491866-42svrqbnpim0uak8tu9cejqjb5u783df.apps.googleusercontent.com"
                }
              >
                <SiteConfigRequired>
                  <SnackbarProvider dense maxSnack={3}>
                    <Router history={history}>
                      <GlobalStyles />
                      <ScrollReset />
                      {/* <GoogleAnalytics /> */}
                      <CookiesNotification />
                      {/* <SettingsNotification /> */}
                      {renderRoutes(routes)}
                    </Router>
                  </SnackbarProvider>
                </SiteConfigRequired>
              </GoogleOAuthProvider>
            </LocalizationProvider>
          </StylesProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

export default App;

const SiteConfigRequired = ({ children }) => {
  //@ts-ignore
  const { siteConfig, adminMode } = useSelector((state) => state.settings);
  var customerId = window["customer_id"];
  const { currentCustomer, customers } = useSelector(
    (state) => state.customers
  );
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (!customers) {
      dispatch(getCustomers());
    }
  }, [customers]);

  React.useEffect(() => {
    if (customers && customers.length > 0 && customerId && !currentCustomer) {
      dispatch(setCustomerById(customerId));
    }
  }, [customers, customerId, currentCustomer]);

  // const { data, error, mutate } = useSWR(
  //   `${arkadu.urls.site.config}?version=${siteConfig.version || 0}`,
  //   apiGet
  // );

  // useEffect(() => {
  //   if (data) {
  //     localStorage.setItem("config", JSON.stringify(data.data.config));
  //     localStorage.setItem("customers", JSON.stringify(data.data.customers));
  //     dispatch(loadCustomers());
  //     dispatch(saveSettings({ settings: { siteConfig: data.data.config } }));
  //     setTimeout(() => {
  //       window.location.reload();
  //     }, 1000);
  //     // dispatch(setCustomers({ data: data.data.customers } || []));
  //   }
  //   if (error && !customers && siteConfig.version !== 0) {
  //     // reset site config version to 0
  //     dispatch(
  //       saveSettings({
  //         settings: { siteConfig: { ...siteConfig, version: 0 } },
  //       })
  //     );
  //     setTimeout(() => {
  //       window.location.reload();
  //     }, 1000);
  //   }
  // }, [data, error]);

  useEffect(() => {
    if (adminMode) {
      // reset config version to 0
      if (siteConfig.version !== 0) {
        dispatch(
          saveSettings({
            siteConfig: { ...siteConfig, version: 0 },
          })
        );
      }
    }
  }, [adminMode]);

  return (
    <MainSiteConfigRequired siteConfig={siteConfig}>
      {children}
    </MainSiteConfigRequired>
  );
};

const MainSiteConfigRequired = ({ children, siteConfig }) => {
  const { customers } = useSelector((state) => state.customers);
  const dispatch = useDispatch();
  const { data, error, mutate } = useSWR(
    `${coreConfig.urls.site.config}?version=${siteConfig.version || 0}`,
    apiGet
  );

  useEffect(() => {
    if (data) {
      localStorage.setItem("config", JSON.stringify(data.data.config));
      localStorage.setItem("customers", JSON.stringify(data.data.customers));
      dispatch(loadCustomers());
      dispatch(saveSettings({ siteConfig: data.data.config }));
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);
      // dispatch(setCustomers({ data: data.data.customers } || []));
    }
    if (error && !customers && siteConfig.version !== 0) {
      // reset site config version to 0
      dispatch(
        saveSettings({
          siteConfig: { ...siteConfig, version: 0 },
        })
      );
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);
    }
  }, [data, error]);
  return <>{children}</>;
};
