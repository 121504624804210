
import {
  AccountBalanceRounded,
  AccountBalanceWalletRounded,
  CancelRounded,
  DoneRounded,
  HowToRegRounded,
  TrendingFlatRounded,
} from "@mui/icons-material";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import LoadingScreen from "src/components/LoadingScreen";
import ResponsiveButton from "src/components/ResponsiveButton";
import { clearBank, processTransfer } from "src/slices/bank";
import { useDispatch, useSelector } from "src/store";
import { numberFormat } from "src/utils/dataRenders";
import BasePage from "src/views/BasePage";
import BankServiceHeader from "./ServiceHeader";

const TransferConfirm = () => {
  const {
    transferInProgress,
    currentService,
    loginRequired,
    transferResult,
    bankError,
    transferRequesting,
  } = useSelector((state) => state.bank);
  const { currentCustomer, currentApp } = useSelector(
    (state) => state.customers
  );
  const [loading, setLoading] = useState(false);

  const handleServiceLogout = () => {
    window.location.replace(currentService.urls.logout);
  };

  const handlePayment = () => {
    setLoading(true);
    console.log(transferInProgress);
    dispatch(processTransfer({ ...transferInProgress, service_id: currentService.id }));
  };

  useEffect(() => {
    if (bankError || transferResult) {
      setLoading(false);
    }
  }, [bankError, transferResult]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(
    transferInProgress ? transferInProgress.amount : 0
  );
  const history = useHistory();

  useEffect(() => {
    if (loginRequired) {
      window.location.replace(
        `${currentService.urls.login}?service_id=${currentService.id}`
      );
    }
  }, [loginRequired]);

  const handleCancel = () => {
    dispatch(clearBank());
    window.location.replace(`/${currentCustomer.shortname}/bank/`);
  };

  useEffect(() => {
    setLoading(false);
    if (transferResult) {
      history.push(
        `/${currentCustomer.shortname}/bank/transfer/result/${transferResult.id}/`,
        {
          result: transferResult,
        }
      );
    }
  }, [transferResult]);

  if (loading) {
    return <LoadingScreen message={t("Procesando solicitud")} />;
  }

  return (
    <BasePage title={t("Confirmar Transferencia")}>
      <Paper>
        <Box p={3}>
          {currentService && currentCustomer && (
            <BankServiceHeader
              currentCustomer={currentCustomer}
              currentService={currentService}
              currentApp={currentApp}
            />
          )}
          <Grid item sm={12}>
            <Grid container direction="row">
              <Grid item sm={12}>
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <AccountBalanceRounded />
                    </ListItemAvatar>
                    <ListItemText>
                      <Grid container direction="column">
                        <Typography variant="h5">
                          <strong>{t("Cuenta origen")}:</strong>{" "}
                          {transferInProgress.accountFrom.accountId}
                        </Typography>
                        <Typography variant="subtitle2">
                          {t("Balance")}{" "}
                          {numberFormat({
                            value: transferInProgress.accountFrom.balance,
                          })}
                        </Typography>
                      </Grid>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <AccountBalanceWalletRounded />
                    </ListItemAvatar>
                    <ListItemText>
                      <Grid container direction="column">
                        <Typography variant="h5">
                          <strong>{t("Cuenta beneficiario")}</strong>
                        </Typography>
                        <Typography variant="h6">
                          <strong>{t("Nombre del titular")}</strong>{" "}
                          {transferInProgress.accountTo.customerName}
                        </Typography>
                        <Typography variant="h6">
                          <strong>{t("N�mero de cuenta")}</strong>{" "}
                          {transferInProgress.accountTo.accountId}
                        </Typography>
                        <Typography variant="h6">
                          <strong>{t("Documento de identidad")}</strong>{" "}
                          {transferInProgress.accountTo.customerId}
                        </Typography>
                      </Grid>
                    </ListItemText>
                  </ListItem>
                  {transferInProgress && transferInProgress.userAccount && (
                    <ListItem>
                      <ListItemAvatar>
                        <HowToRegRounded />
                      </ListItemAvatar>
                      <ListItemText>
                        <Grid container direction="column">
                          <Typography variant="h5">
                            {t("Abono a cuenta")}{" "}
                            {transferInProgress.userAccount.customer.name} :: #
                            {transferInProgress.userAccount.id}
                          </Typography>
                          <Typography variant="subtitle1">
                            {transferInProgress.userAccount.identity.legal_name}{" "}
                            {
                              transferInProgress.userAccount.identity
                                .national_id
                            }
                          </Typography>
                          <Typography variant="subtitle2">
                            {t("Balance")}{" "}
                            {numberFormat({
                              value: transferInProgress.userAccount.total,
                            })}
                          </Typography>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                  )}
                  <ListItem>
                    <ListItemAvatar>
                      <TrendingFlatRounded />
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography variant="h5">
                        <strong>{t("Monto")}</strong>{" "}
                        {numberFormat({ value: transferInProgress.amount })}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <Grid item container>
                <Grid item sm={6} align="left">
                  <ResponsiveButton
                    variant="contained"
                    title={t("Cancelar")}
                    color="error"
                    onClick={() => {
                      handleCancel();
                    }}
                    startIcon={<CancelRounded />}
                  >
                    {t("Cancelar")}
                  </ResponsiveButton>
                </Grid>
                <Grid item sm={6} align="right">
                  <ResponsiveButton
                    title={t("Pagar")}
                    variant="contained"
                    onClick={() => {
                      handlePayment();
                    }}
                    color="success"
                    startIcon={<DoneRounded />}
                  >
                    {t("Pagar")}
                  </ResponsiveButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </BasePage>
  );
};

export default TransferConfirm;
